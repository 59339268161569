@import "./assets/css/purple-green-stp.css";

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.button-close {
  background-color: white;
  color: #363895;
  border: none;
}

div.close-div{
  display: flex;
  justify-content: flex-end;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

a.has-sub.mat-list-item.mat-list-item-disabled{
  color: gray;
}


div.audit-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  padding-top: 1.5em;
  padding-left: 2em;
  top: 0;
  background-color: white;
  z-index:99;
}

::ng-deep .mainheader div.dropdown-menu {
  z-index: 100;
}

.float-right {
  float: right;
}

.evenly {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}
.mat-grid-tile .mat-figure {
  align-items: flex-start !important;
}

#content{
  background: white;
  color: black;

}

ul div {
  margin-top: 12px;
}


.text-inside-list:hover {
  color: #EC8F22;
  cursor: pointer;
}


.main-wrapper {
  min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -50px;
}
.footer,
.push {
  height: 50px;
}

.mat-card-content .mat-divider{
  border-top-style: dashed;
}

::ng-deep span.mat-expansion-indicator{
  padding-right: 0.5em;
}

::ng-deep .tooltip1 {
  background: #EC8F22;
  color: white !important;
  font-size: 1.1em !important;
}

 mat-header-row.mat-header-row {
  background-color: #363895;
  color: white;
  font-size: 1.1em;
}

thead.mat-header-row {
  background-color: #363895;
  color: white;
  font-size: 1.1em;
}
mat-paginator, ::ng-deep mat-paginator .mat-select-value , ::ng-deep mat-paginator .mat-select-arrow{
  background-color:  transparent;
  color: black;
  font-size: 1.1em;
}


 mat-row.mat-row:nth-child(odd) {
  background-color: lightgrey;
  color: black;
}

mat-row.mat-row:nth-child(even) {
    background-color: white;
    color: black;
}

::ng-deep .mat-form-field-type-mat-native-select   .mat-form-field-underline{
  background-color: black;
}

::ng-deep .mat-form-field-type-mat-native-select .mat-select-arrow{
  background-color: black;
}

::ng-deep .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
color: black
}

::ng-deep  mat-table mat-form-field .mat-form-field-infix {
  width: 6em;
}

::ng-deep div.mat-list-item-content{
  padding-left: 2em;
}

@media (min-width: 1880px){
  .container {
    width: 1660px;
}
}

@media print {
  div.print{
      display: block !important;
  }

  .screen {
    display: none !important;
  }

  .stp-header {
    height: 0 !important;
  }
}